
import Vue from 'vue'

export default Vue.extend({
  name: 'ForgotPasswordForm',
  data () {
    return {
      email: '',
      isValid: false
    }
  },
  methods: {
    forgotPassword () {
      this.$emit('forgotPassword', { email: this.email })
    },
    cancelForgotPassword () {
      this.$emit('cancelForgotPassword')
    },

    checkInputs () {
      this.email = this.email.trim()
      const emailRegEx = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/
      const isValidEmail = emailRegEx.test(this.email)
      if (this.email.length && isValidEmail) {
        this.isValid = true
        return
      }
      this.isValid = false
    }
  }
})

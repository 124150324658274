
import Vue from 'vue'
import { BtnInterface } from '@/lib/interfaces'
import Apiclient from '@/plugins/apiclient'
import store from '@/store'

export default Vue.extend({
  name: 'UserForm',
  props: {
    buttons: {
      type: Array,
      default: () : Array<BtnInterface> => []
    }
  },
  data () {
    return {
      formData: {
        current_password: null,
        password: null,
        confirm_password: null
      },
      rules: {
        required: value => !!value || 'Required.',
        passwordsMatch: value => (value === this.formData.password) || 'New password and the password confirmation do not match!'
      }
    }
  },
  methods: {
    async triggerButton () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      const { getCurrentUser } = store.getters
      const { password, confirm_password } = this.formData
      if (password !== confirm_password) {
        alert('New password and the password confirmation do not match!')
        return
      }

      await Apiclient.put(`/users/${getCurrentUser.auth_token.user_id}/password`, this.formData)
        .then(() => {
          alert('Your password has been changed!')
          this.$router.push('/users')
        })
        .catch((error) => {
          if (error.response.status === 403) {
            alert('Current Password field does not match the existing password!')
          } else {
            alert('An error occurred when trying to change your password')
          }
        })
    }
  }
})

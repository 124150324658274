
import Vue from 'vue'
import BaseList from '@/components/lists/BaseList.vue'

export default Vue.extend({
  name: 'MissingItemsView',
  components: { BaseList },
  data () {
    return {
      headers: [
        {
          text: 'Id',
          value: 'id',
          sortable: true,
          searchable: true
        },
        {
          text: 'Trading Partner',
          value: 'trading_partner_id',
          sortable: true,
          searchable: true
        },
        {
          text: 'Item UPC',
          value: 'item_upc',
          sortable: true,
          searchable: true
        },
        {
          text: 'Requested on',
          value: 'requested_on',
          sortable: true,
          searchable: true
        },
        {
          text: 'Reason',
          value: 'reason',
          sortable: true,
          searchable: true
        }
      ]
    }
  }
})

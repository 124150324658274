
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import ApiClient from '@/plugins/apiclient'

export default Vue.extend({
  name: 'RetailerSettings',
  props: ['rules', 'shouldBeReadOnly'],
  data () {
    return {
      availableUsers: [],
      selectedUsers: [],
      purchaseOrderTypes: [],
      defaultActions: [
        {
          label: 'Generate backorders automatically',
          value: 'accept_immediately'
        },
        {
          label: 'Reject backorders automatically',
          value: 'reject_immediately'
        },
        {
          label: 'Generate backorders after timeout',
          value: 'accept_timeout'
        },
        {
          label: 'Reject backorders after timeout',
          value: 'reject_timeout'
        }
      ]
    }
  },
  async mounted () {
    let res = await ApiClient.get('purchase-order-types')
    this.purchaseOrderTypes = res.data
    res = await ApiClient.get('trading-partner-managers')
    this.availableUsers = res.data
  },
  computed: {
    ...mapGetters({
      formData: 'getFormRecord'
    })
  },
  methods: {
    updateSelectedUsers () {
      this.$store.commit(
        'setTradingPartnerManagers',
        this.selectedUsers.map((u) => { return u.email })
      )
    },

    ...mapActions(['hideModal', 'setError', 'setModalFormRecord', 'setLocationModalFormRecord', 'clearLocationModalRecord', 'clearModalRecord', 'showModal'])
  }
})

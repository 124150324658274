import { render, staticRenderFns } from "./BasicSettings.vue?vue&type=template&id=2474e144&scoped=true&"
import script from "./BasicSettings.vue?vue&type=script&lang=ts&"
export * from "./BasicSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2474e144",
  null
  
)

export default component.exports

import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import BaseList from '@/components/lists/BaseList.vue'
import { BtnEventInterface, BtnEventNameInterface } from '@/lib/interfaces'
import ModalForm from '@/components/common/ModalForm.vue'
import LocationForm from '@/components/forms/LocationForm.vue'
import ApiClient from '@/plugins/apiclient'

export default Vue.extend({
  name: 'LocationSettings',
  components: {
    LocationForm,
    ModalForm,
    BaseList
  },
  props: ['rules', 'shouldBeReadOnly'],
  data () {
    return {
      headers: [
        {
          text: 'Store',
          value: 'store',
          sortable: true
        },
        {
          text: 'Address',
          value: 'address',
          sortable: true
        },
        {
          text: 'Store Manager',
          value: 'store_mgr',
          sortable: true
        },
        {
          text: 'Phone',
          value: 'phone',
          sortable: true
        },
        {
          text: 'Fax',
          value: 'fax',
          sortable: true
        },
        {
          text: 'Invoice Email',
          value: 'invoice_email',
          sortable: true
        }
      ],
      formButtons: [
        {
          label: 'Cancel',
          id: 'applicationform-button-cancel',
          outlined: true,
          autoClose: true
        },
        {
          label: 'Save',
          id: 'applicationform-button-save'
        }
      ],
      recordOptions: [
        {
          icon: 'mdi-pencil',
          label: 'Edit',
          color: 'primary'
        }
      ],
      listButtons: [
        {
          icon: 'mdi-plus',
          label: 'New Location',
          id: 'locationlist-button-new'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      formData: 'getFormRecord'
    })
  },
  methods: {
    btnClick (btnEvent) {
      switch (btnEvent.eventName) {
        case 'new_location': {
          this.clearLocationModalRecord()
          this.setLocationModalFormRecord({ trading_partner_id: this.formData.id })
          this.showModal()
        }
      }
    },

    async optClick (eventInfo: BtnEventNameInterface) {
      if (eventInfo.eventName === 'edit') {
        this.setLocationModalFormRecord(eventInfo.eventData)
        this.showModal()
      }
    },

    async buttonClick (eventData: BtnEventInterface) {
      if (eventData.button.label === 'Save') {
        try {
          if (!eventData.data.id) {
            const res = await ApiClient.post(`/vendors/${this.formData.id}/locations`, eventData.data)
            if (res.status === 200) {
              this.setSuccessMessage('The new location has been created')
            }
          } else {
            const res = await ApiClient.put(`/vendors/${this.formData.id}/locations/${eventData.data.id}`, eventData.data)
            if (res.status === 200) {
              this.setSuccessMessage('The location data has been updated')
            }
          }
          const ref = this.$refs.listing as unknown
          ref.refresh()
          this.hideModal()
        } catch (e) {
          this.setError(e)
        }
      } else if (eventData.button.label === 'Cancel') {
        const ref = this.$refs.listing as unknown
        ref.refresh()
      }
    },

    ...mapActions(['hideModal', 'setError', 'setModalFormRecord', 'setLocationModalFormRecord', 'clearLocationModalRecord', 'clearModalRecord', 'showModal', 'setSuccessMessage'])
  }
})

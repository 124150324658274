
import Vue from 'vue'
import Apiclient from '@/plugins/apiclient'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'OrderSettings',
  props: {
    name: {
      type: String,
      default: 'Trading Partner'
    }
  },
  computed: {
    ...mapGetters({
      formData: 'getFormRecord'
    })
  },
  data () {
    return {
      orders: []
    }
  },
  methods: {
    showDetails (orderId: string) {
      try {
        this.$router.push({
          name: 'purchase-order-detail',
          params: {
            purchaseOrderId: orderId
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
  },
  async mounted () {
    const res = await Apiclient.get(`/vendors/${this.formData.id}/orders`)
    this.orders = res.data
  }
})

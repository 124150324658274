
import Vue from 'vue'
import Apiclient from '@/plugins/apiclient'

export default Vue.extend({
  name: 'TradingPartnerManagerHome',
  data () {
    return {
      tradingPartners: []
    }
  },
  async mounted () {
    const res = await Apiclient.get('/my-trading-partners')
    this.tradingPartners = res.data
  }
})


import Vue from 'vue'

export default Vue.extend({
  name: 'ResetPasswordForm',
  data () {
    return {
      otp: '',
      isValid: false,
      isVerifyOtp: false,
      password: '',
      confirmPassword: '',
      isValidPasswordInput: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        sameAsPassword: v => v === this.password || "Password confirmation doesn't match password"
      }
    }
  },
  methods: {
    verifyOtp () {
      this.$emit('verifyOtp', this.otp, () => {
        this.isVerifyOtp = true
      })
    },

    resetPassword () {
      this.$emit('resetPassword', this.otp, { password: this.password, confirm_password: this.confirmPassword })
    },

    checkOtpInputs () {
      this.otp = this.otp.trim()
      if (this.otp.length === 6) {
        this.isValid = true
        return
      }
      this.isValid = false
    },

    checkInputs () {
      this.password = this.password.trim()
      this.confirmPassword = this.confirmPassword.trim()
      if (this.password.length >= 8 && this.password === this.confirmPassword) {
        this.isValidPasswordInput = true
        return
      }
      this.isValidPasswordInput = false
    }
  }
})

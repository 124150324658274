
import Vue from 'vue'
import BaseList from './BaseList.vue'
import ModalForm from '@/components/common/ModalForm.vue'
import ApplicationForm from '@/components/forms/ApplicationForm.vue'
import Apiclient from '@/plugins/apiclient'
import { mapActions } from 'vuex'
import { BtnEventInterface, BtnEventNameInterface } from '@/lib/interfaces'
import messages from '@/lang/en.json'

export default Vue.extend({
  name: 'ApplicationsList',
  components: { BaseList, ModalForm, ApplicationForm },

  data () {
    return {
      headers: [
        {
          text: 'Id',
          value: 'id',
          sortable: true
        },
        {
          text: 'Name',
          value: 'name',
          sortable: true
        },
        {
          text: 'Description',
          value: 'description',
          sortable: false
        },
        {
          text: 'Is Enabled',
          value: 'enabled',
          sortable: false
        }
      ],
      buttons: [
        {
          icon: 'mdi-plus',
          label: 'New Application',
          id: 'applicationslist-button-new'
        }
      ],
      formButtons: [
        {
          label: 'Cancel',
          id: 'applicationform-button-cancel',
          outlined: true,
          autoClose: true
        },
        {
          label: 'Save',
          id: 'applicationform-button-save'
        }
      ],
      recordOptions: [
        {
          icon: 'mdi-pencil',
          label: 'Edit',
          color: 'primary'
        }
      ]
    }
  },
  methods: {
    btnClick (btnEvent) {
      switch (btnEvent.eventName) {
        case 'new_application':
          this.clearModalRecord()
          this.showModal()
      }
    },

    async buttonClick (eventData: BtnEventInterface) {
      if (eventData.button.label === 'Save') {
        try {
          if (!eventData.data.id) {
            const res = await Apiclient.post('/applications', eventData.data)
            if (res.status === 200) {
              this.setSuccessMessage('The new applications has been created')
            }
          } else {
            const res = await Apiclient.put(`/applications/${eventData.data.id}`, eventData.data)
            if (res.status === 200) {
              this.setSuccessMessage('The applications has been updated')
            }
          }
          const ref = this.$refs.listing as unknown
          ref.refresh()
          this.hideModal()
        } catch (e) {
          this.setError(e)
        }
      }
    },

    async optClick (eventInfo: BtnEventNameInterface) {
      if (eventInfo.eventName === 'edit') {
        this.setModalFormRecord(eventInfo.eventData)
        this.showModal()
      }
    },
    getMessage (key:string): string {
      return messages[key]
    },
    ...mapActions([
      'clearModalRecord',
      'hideModal',
      'setModalFormRecord',
      'showModal',
      'setError',
      'setSuccessMessage'
    ])
  }
})

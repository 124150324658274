
import Vue from 'vue'
import Apiclient from '@/plugins/apiclient'
import { mapActions, mapGetters } from 'vuex'
import { format } from 'date-fns'

export default Vue.extend({
  name: 'BaseList',
  props: {
    title: String,
    headers: Array,
    url: String,
    showSearch: {
      type: Boolean,
      default: true
    },
    showOptions: {
      type: Boolean,
      default: true
    },
    description: {
      type: String,
      default: ''
    },
    buttons: {
      type: Array,
      default: () => []
    },
    recordOptions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    tableHeaders (): Array<unknown> {
      const tableHeaders = this.headers
      if (this.showOptions) {
        tableHeaders.push({
          text: 'Options',
          value: 'options'
        })
      }
      return tableHeaders
    },

    ...mapGetters(['isLoading', 'getCurrentError'])
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    async refresh () {
      this.startLoading()
      this.items = []
      try {
        const res = await Apiclient.get(this.url)
        this.items = res.data
        this.finishLoading()
      } catch (e) {
        console.error(e)
      }
    },

    formatDate (timestamp) {
      return format(new Date(timestamp * 1000), 'yyyy-MM-dd HH:mm:ss')
    },

    optionClick (label: string, item: unknown) {
      const eventName = label.toLowerCase().replaceAll(' ', '_')
      this.$emit('optClick', { eventName, eventData: item })
    },

    triggerEvent (name: string, item: unknown) {
      const eventName = name.toLowerCase().replaceAll(' ', '_')
      this.$emit('btnClick', { eventName, eventData: item })
    },

    ...mapActions(['startLoading', 'finishLoading'])
  }
})

import store from '../store/index'
import Apiclient from '@/plugins/apiclient'
import cookie from './cookie'

class Authenticate {
  async authenticate (email: string, password: string): Promise<boolean> {
    // @todo get csrf token
    const csrf = await Apiclient.get('/csrf-token')
    const res = await Apiclient.post('/authenticate', { email, password }, csrf.data.token)
    if (res.data && res.data.result) {
      await store.dispatch('login', res.data.user)
      cookie.create(
        res.data.user.auth_token.token,
        res.data.user.auth_token.expiration_time
      )
      return true
    }
    return false
  }

  /* eslint-disable lines-between-class-members, no-trailing-spaces */
  async forgotPassword (email: string): Promise <boolean> {
    const res = await Apiclient.get(`/generate-token/${email}`)
    return !!(res.data && res.data.result)
  }
  /* eslint-disable lines-between-class-members, no-trailing-spaces */

  async verifyToken (token: string): Promise <boolean> {
    const res = await Apiclient.get(`/verify-token/${token}`)
    return !!(res.data && res.data.result)
  }

  async resetPassword (token: string, body: Record<string, string>) {
    const res = await Apiclient.put(`/reset-password/${token}`, body)
    return res
  }
}

export default new Authenticate()

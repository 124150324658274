
import Vue from 'vue'
import BaseList from '@/components/lists/BaseList.vue'
import Apiclient from '@/plugins/apiclient'
import { mapActions } from 'vuex'
import { BtnEventNameInterface } from '@/lib/interfaces'
import messages from '@/lang/en.json'

export default Vue.extend({
  name: 'VendorsPage',
  components: { BaseList },
  data () {
    return {
      isError: true,
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true
        },
        {
          text: 'Is Enabled',
          value: 'is_enabled'
        },
        {
          text: 'Role',
          value: 'role'
        },
        {
          text: 'Description',
          value: 'description',
          sortable: false
        }
      ],
      buttons: [
        {
          icon: 'mdi-plus',
          label: 'New Trading Partner',
          id: 'vendorslist-button-new'
        }
      ],
      recordOptions: [
        {
          icon: 'mdi-pencil',
          label: 'Edit',
          color: 'primary'
        }
      ]

    }
  },

  methods: {
    async btnClick (btnEvent) {
      switch (btnEvent.eventName) {
        case 'new_trading_partner': {
          this.clearModalRecord()
          // this.showModal()
          await this.$router.push({
            name: 'trading-partner-new'
          })
        }
      }
    },

    async optClick (eventInfo: BtnEventNameInterface) {
      switch (eventInfo.eventName) {
        case 'edit':
          await this.$router.push({
            name: 'trading-partner-detail',
            params: {
              vendorId: eventInfo.eventData.id
            }
          })
          break
        case 'fetch':
          try {
            alert('Fetch was ran successfully. Please visit the transfer log module to see the operation result ')
            await Apiclient.post(`/vendors/${eventInfo.eventData.id}/fetch`)
            alert('Fetch was ran successfully. Please visit the transfer log module to see the operation result ')
          } catch (e) {
            this.setError(e)
          }
          break
        case 'push':
          try {
            alert('Push was ran successfully. Please visit the transfer log module to see the operation result')
            await Apiclient.post(`/vendors/${eventInfo.eventData.id}/put`)
            alert('Push was ran successfully. Please visit the transfer log module to see the operation result')
          } catch (e) {
            this.setError(e)
          }
          break
      }
    },
    getMessage (key:string): string {
      return messages[key]
    },

    ...mapActions(['showModal', 'hideModal', 'clearModalRecord', 'setModalFormRecord', 'setError'])
  }
})

import { render, staticRenderFns } from "./RetailerSettings.vue?vue&type=template&id=38bcddfa&scoped=true&"
import script from "./RetailerSettings.vue?vue&type=script&lang=ts&"
export * from "./RetailerSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38bcddfa",
  null
  
)

export default component.exports

import Vue from 'vue'
import { BtnInterface } from '@/lib/interfaces'
import { mapActions, mapGetters } from 'vuex'
import BasicSettings from '@/components/forms/parts/BasicSettings.vue'
import EdiSettings from '@/components/forms/parts/EdiSettings.vue'
import TransportSettings from '@/components/forms/parts/TransportSettings.vue'
import NotificationSettings from '@/components/forms/parts/NotificationSettings.vue'
import RetailerSettings from '@/components/forms/parts/RetailerSettings.vue'
import LocationSettings from '@/components/forms/parts/LocationSettings.vue'
import OrderSettings from '@/components/forms/parts/OrderSettings.vue'

export default Vue.extend({
  name: 'TradingPartnerForm',
  components: {
    OrderSettings,
    LocationSettings,
    RetailerSettings,
    NotificationSettings,
    TransportSettings,
    BasicSettings,
    EdiSettings
  },
  props: {
    buttons: {
      type: Array,
      default: (): Array<BtnInterface> => []
    }
  },
  data () {
    return {
      tab: 0,
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  computed: {
    shouldBeReadOnly () {
      return this.$store.getters.getCurrentUser.role === 'manager'
    },
    isValid () {
      return this.formData.name?.trim().length > 0 &&
        this.formData.edi_method?.trim().length > 0
    },
    ...mapGetters({
      formData: 'getFormRecord'
    })
  },
  methods: {
    triggerButton (button: BtnInterface) {
      if (button.autoClose) {
        this.hideModal()
      } else {
        if (button.label.toLowerCase() !== 'cancel') {
          if (this.isValid) {
            this.$emit('btnClick', {
              button,
              data: this.formData
            }, this.tab)
          } else {
            alert('Please make sure you have input Trading Partner Name, Elliott ID and have selected the EDI method')
          }
        } else {
          this.$emit('btnClick', {
            button,
            data: this.formData
          }, this.tab)
        }
      }
    },

    ...mapActions(['hideModal', 'setError', 'setModalFormRecord', 'setLocationModalFormRecord', 'clearLocationModalRecord', 'clearModalRecord', 'showModal'])
  }
})

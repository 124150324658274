
import Vue from 'vue'
import LoginForm from '@/components/forms/LoginForm'
import ForgotPasswordForm from '@/components/forms/ForgotPasswordForm'
import ResetPasswordForm from '@/components/forms/ResetPasswordForm'
import AuthClient from '@/plugins/auth'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'LoginView',
  data () {
    return {
      authStep: 1
    }
  },
  components: { LoginForm, ForgotPasswordForm, ResetPasswordForm },
  methods: {
    async login (credentials: Record<string, string>) {
      const res = await AuthClient.authenticate(credentials.email, credentials.password)
      if (res) {
        this.$router.push('/')
      } else {
        this.setError({
          message: 'Invalid Credentials'
        })
      }
    },
    async forgotPassword (credentials: Record<string, string>) {
      await AuthClient.forgotPassword(credentials.email)
      this.goToResetPassword()
    },
    async verifyOtp (token: string, cb: () => void) {
      const res = await AuthClient.verifyToken(token)
      if (res) {
        cb()
      } else {
        this.setError({
          message: 'Invalid token'
        })
      }
    },
    async resetPassword (token: string, body: Record<string, string>) {
      const res = await AuthClient.resetPassword(token, body)
      if (res.status === 200) {
        this.setSuccessMessage('Your password has been changed')
      }
      this.goToLogin()
    },
    goToLogin () {
      this.authStep = 1
    },
    goToForgotPassword () {
      this.authStep = 2
    },
    goToResetPassword () {
      this.authStep = 3
    },

    ...mapActions(['setError', 'setSuccessMessage'])
  }
})

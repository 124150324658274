import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import TradingPartnersView from '@/views/TradingPartnersView.vue'
import TradingPartnerView from '@/views/TradingPartnerView.vue'
import ApplicationsView from '@/views/ApplicationsView.vue'
import UsersView from '@/views/UsersView.vue'
import ChangePasswordView from '@/views/ChangePasswordView.vue'
import PurchaseOrdersView from '@/views/PurchaseOrdersView.vue'
import PurchaseOrderView from '@/views/PurchaseOrderView.vue'
import ApplicationSettings from '@/views/ApplicationSettings.vue'
import ElliottFiles from '@/views/ElliottFiles.vue'
import SerialNumbers from '@/views/SerialNumbers.vue'
import TransferLog from '@/views/TransferLog.vue'
import ReconciliationView from '@/views/ReconciliationView.vue'
import NotificationsView from '@/views/NotificationsView.vue'
import MissingItemsView from '@/views/MissingItemsView.vue'

export default [
  {
    path: '/',
    name: 'dashboard',
    component: HomeView,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Dashboard',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/reconciliation',
    name: 'reconciliation',
    component: ReconciliationView,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Reconciliation',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: ApplicationSettings,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Application Settings',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/elliott-files',
    name: 'elliott-files',
    component: ElliottFiles,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Elliott Files',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/transfer-log',
    name: 'transfer-log',
    component: TransferLog,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Transfer Log',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/serial-numbers',
    name: 'serial-numbers',
    component: SerialNumbers,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Serial Numbers',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/missing-items',
    name: 'missing-items',
    component: MissingItemsView,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Missing Items',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/trading-partners',
    name: 'trading-partners',
    component: TradingPartnersView,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Trading Partners',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/trading-partners/new',
    name: 'trading-partner-new',
    component: TradingPartnerView,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Trading Partners',
          href: '/trading-partners'
        },
        {
          text: 'New Trading Partner',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/trading-partners/:vendorId',
    name: 'trading-partner-detail',
    component: TradingPartnerView,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Trading Partner',
          href: '/trading-partners'
        },
        {
          text: 'Edit Trading Partner',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/applications',
    name: 'applications',
    component: ApplicationsView,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Applications',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Users',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePasswordView,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Change Password'
        }
      ]
    }
  },
  {
    path: '/purchase-orders',
    name: 'purchase-orders',
    component: PurchaseOrdersView,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Purchase orders',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/purchase-orders/:purchaseOrderId',
    name: 'purchase-order-detail',
    component: PurchaseOrderView,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Purchase Orders',
          href: '/purchase-orders'
        },
        {
          text: 'Purchase Order Detail',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: NotificationsView,
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Notifications',
          disabled: true
        }
      ]
    }
  }
]

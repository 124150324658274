
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'BasicTradingPartnerSettings',
  props: ['rules', 'shouldBeReadOnly'],
  data () {
    return {
      tpTypes: [
        {
          label: 'Vendor - Outgoing Orders',
          value: 'vendor'
        },
        {
          label: 'Buyer - Incoming Orders',
          value: 'buyer'
        }
      ],
      ediMethod: [
        {
          label: 'Secure FTP (SFTP)',
          value: 'sftp'
        },
        {
          label: 'Lightwell VAN',
          value: 'van'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      formData: 'getFormRecord'
    })
  },
  methods: {
    ...mapActions(['hideModal', 'setError', 'setModalFormRecord', 'setLocationModalFormRecord', 'clearLocationModalRecord', 'clearModalRecord', 'showModal'])
  }
})


import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'TransportSettings',
  props: ['rules', 'shouldBeReadOnly'],
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      formData: 'getFormRecord'
    })
  },
  methods: {
    ...mapActions(['hideModal', 'setError', 'setModalFormRecord', 'setLocationModalFormRecord', 'clearLocationModalRecord', 'clearModalRecord', 'showModal'])
  }
})


import Vue from 'vue'
import ErrorBar from '@/components/common/ErrorBar.vue'
import BreadCrumb from '@/components/common/BreadCrumb.vue'
import apiclient from '@/plugins/apiclient'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'App',
  components: { ErrorBar, BreadCrumb },
  computed: {
    isAdmin () {
      return this.$store.getters.getCurrentUser.role === 'admin'
    },
    successMessage () {
      const message = this.getSuccessMessage()
      if (message !== '' && this.clearSuccessMessage === 0) {
        this.clearSuccessMessageIn()
      }
      return message
    },
    availableLinks () {
      return this.navLinks.filter(
        (link) => {
          return this.$store.getters.getCurrentUser && (this.$store.getters.getCurrentUser.role === 'admin' || link.profiles?.indexOf('manager') >= 0)
        }
      )
    }
  },
  data: () => ({
    clearSuccessMessage: 0,
    drawer: false,
    darkMode: false,
    navLinks: [
      {
        icon: 'mdi-view-dashboard',
        text: 'Dashboard',
        link: '/',
        profiles: ['admin', 'manager']
      },
      {
        text: 'separator'
      },
      {
        icon: 'mdi-file-document',
        text: 'Purchase Orders',
        link: '/purchase-orders',
        profiles: ['admin']
      },
      {
        icon: 'mdi-folder',
        text: 'Elliott files',
        link: '/elliott-files',
        profiles: ['admin']
      },
      {
        icon: 'mdi-factory',
        text: 'Trading Partners',
        link: '/trading-partners',
        profiles: ['admin']
      },
      {
        icon: 'mdi-apps',
        text: 'Applications',
        link: '/applications',
        profiles: ['admin']
      },
      {
        icon: 'mdi-account-multiple',
        text: 'Users',
        link: '/users',
        profiles: ['admin']
      },
      {
        icon: 'mdi-swap-vertical-bold',
        text: 'Transfer Log',
        link: '/transfer-log',
        profiles: ['admin']
      },
      {
        icon: 'mdi-vector-union',
        text: 'Reconciliation',
        link: '/reconciliation',
        profiles: ['admin']
      },
      {
        icon: 'mdi-barcode',
        text: 'Serial Numbers',
        link: '/serial-numbers',
        profiles: ['admin']
      },
      {
        icon: 'mdi-alert-circle',
        text: 'Missing Items',
        link: '/missing-items',
        profiles: ['admin']
      },
      {
        text: 'separator'
      },
      {
        icon: 'mdi-key',
        text: 'Change password',
        link: '/change-password',
        profiles: ['admin', 'manager']
      },
      {
        icon: 'mdi-bell-outline',
        text: 'Notifications',
        link: '/notifications',
        profiles: ['admin']
      },
      {
        icon: 'mdi-cog',
        text: 'Settings',
        link: '/settings',
        profiles: ['admin']
      },
      {
        text: 'separator'
      },
      {
        icon: 'mdi-book',
        text: 'Documentation',
        link: '/docs',
        profiles: ['admin', 'manager']
      }
    ]
  }),

  methods: {
    toggleDarkMode () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    async logout () {
      await apiclient.get('logout')
      this.$store.dispatch('logout')
      window.location.href = '/'
    },
    ...mapGetters(['getSuccessMessage']),
    ...mapActions(['setSuccessMessage', 'clearSuccessMessageIn'])
  }
})

import Vue from 'vue'
import Vuex from 'vuex'
import { ErrorInterface } from '@/lib/interfaces'
import cookie from '@/plugins/cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: true,
    modalVisible: false,
    formData: {},
    locationFormData: {},
    currentError: null,
    currentUser: null,
    successMessage: '',
    clearSuccessMessageTimer: 0
  },

  getters: {
    isLoading: (state) : boolean => {
      return state.loading
    },

    isModalVisible: (state) : boolean => {
      return state.modalVisible
    },

    getFormRecord: (state) : Record<string, unknown> | null => {
      return state.formData
    },

    getLocationFormRecord: (state) : Record<string, unknown> | null => {
      return state.locationFormData
    },

    getCurrentError: (state) => {
      return state.currentError
    },

    getCurrentUser: (state) => {
      return state.currentUser
    },

    getSuccessMessage: (state) => {
      return state.successMessage
    }
  },

  mutations: {
    setLoading: (state, loading:boolean) => {
      state.loading = loading
    },

    setModalVisibility: (state, visible: boolean) => {
      state.modalVisible = visible
    },

    setFormData: (state, formData: Record<string, unknown>) => {
      state.formData = formData
    },

    setLocationModalFormData: (state, locationFormData: Record<string, unknown>) => {
      state.locationFormData = locationFormData
    },

    setCurrentError: (state, error) => {
      state.currentError = error
    },

    setCurrentUser: (state, user) => {
      state.currentUser = user
    },

    setTradingPartnerManagers: (state, managers) => {
      (state.formData as any).managers = managers
    },

    setSuccessMessage: (state, message) => {
      state.successMessage = message
      if (state.successMessage === '') {
        state.clearSuccessMessageTimer = 0
      }
    },

    setClearSuccessMessageTimer: (state, timer) => {
      state.clearSuccessMessageTimer = timer
    }
  },

  actions: {
    clearModalRecord (context) {
      context.commit('setFormData', {})
    },

    setModalFormRecord (context, formData) {
      const newFormData = JSON.parse(JSON.stringify(formData))
      context.commit('setFormData', newFormData)
    },

    clearLocationModalRecord (context) {
      context.commit('setLocationModalFormData', {})
    },

    setLocationModalFormRecord (context, formData) {
      context.commit('setLocationModalFormData', formData)
    },

    startLoading (context) {
      context.commit('setLoading', true)
    },

    finishLoading (context) {
      context.commit('setLoading', false)
    },

    showModal (context) {
      context.commit('setModalVisibility', true)
    },

    hideModal (context) {
      context.commit('setModalVisibility', false)
    },

    setError (context, error: ErrorInterface) {
      context.commit('setCurrentError', error)
    },

    login (context, user) {
      context.commit('setCurrentUser', user)
    },

    logout (context) {
      context.commit('setCurrentUser', null)
      cookie.delete()
    },

    setSuccessMessage (context, message) {
      context.commit('setSuccessMessage', message)
    },

    clearSuccessMessageIn (context, timeout) {
      if (context.state.clearSuccessMessageTimer === 0) {
        context.commit('setClearSuccessMessageTimer', setTimeout(
          () => {
            context.commit('setSuccessMessage', '')
            context.commit('setClearSuccessMessageTimer', 0)
          },
          5000
        ))
      }
    }
  },

  modules: {
  }
})
